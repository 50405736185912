
// Body
$body-bg: #f5f8fa;


// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: rgb(255,200,69); // #FFC845
$brand-info: #3097D1;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
// $text-color: #636b6f;
$text-color: black;

// Link Color
//$link-color: darken($brand-primary, 10%);
$link-color: black;
$link-hover-color: darken($brand-primary, 10%);

// Navbar
$navbar-default-bg: rgb(255,200,69);
$navbar-inverse-bg: rgb(255,200,69);
$navbar-inverse-link-color: $text-color;
$navbar-inverse-link-hover-color: lighten($brand-primary, 30%);
$navbar-default-link-hover-color: $text-color;
$nav-pills-active-link-hover-color: $text-color;
$nav-link-hover-bg: lighten($brand-primary, 38%);

// Buttons
$btn-default-color: $text-color;
$btn-primary-color: $text-color;
$btn-primary-border: darken($brand-primary, 25%);

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: #3097D1;
$input-color-placeholder: lighten($text-color, 70%);
$input-color: $text-color;

// Panels
$panel-default-heading-bg: rgb(255,200,69);
$panel-primary-text: $text-color;

// Tabs
$nav-tabs-active-link-hover-bg: lighten($brand-primary, 25%);
$nav-tabs-link-hover-border-color: lighten($brand-primary, 25%);
$nav-tabs-active-link-hover-border-color: lighten($brand-primary, 25%);

// Grid
$grid-gutter-width: 20px;

