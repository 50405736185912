.sidebar{
    .menu-collapse{
        min-height: auto;
        padding: 0 15px;
        margin-bottom:0;
    }
    .sidebar-toggle{
        @media(min-width: 768px)
        {
            display:none;
        }
        display:flex;
        align-items:center;
        justify-content: space-between;

        button{
            border-color: #333;
            border: 1px solid;
            .icon-bar{
                background: $navbar-default-bg;
            }
        }
    }

    .branch-name{
        @media(max-width: 766px) {
            display: none;
        }
    }
}